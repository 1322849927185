<template>
  <div class="router-apply-wrap">
    <div class="bread-wrap">
      <span class="bread-title">直播快/申请路由器</span>
    </div>
    <div class="content">
      <div class="content-right">
        <div class="ffxx">
          <p class="title-wrap">
            <i class="iconfont icon-bill-detail titleicon"></i><span class="title">付费信息</span>
          </p>
          <div class="ff-detail">
            <span class="dtcontent">路由器类型</span>
            <span class="dttext">{{ chooseRouter }}</span>
          </div>

          <div class="ff-detail">
            <span class="dtdiscount">优惠金额</span><span class="dtdiscount">{{ discounts }} 元</span>
          </div>
          <div class="single-line"></div>
          <div class="ff-detail">
            <span class="dtprice">合计费用</span><span class="dtamount">{{ totalAmount }} 元</span>
          </div>

          <el-radio-group v-model="payType" class="payradio">
            <el-radio :disabled="!balanceEnough"
                      :label="PAY_TYPES.BALANCE.type"
                      class="itemradio">余额支付(可用:{{ balance | centToYuan }})
            </el-radio>
            <el-radio :label="PAY_TYPES.WECHAT_NATIVE.type" class="itemradio">
              <i class="iconfont icon-weixinzhifu"/>微信支付
            </el-radio>
            <el-radio :label="PAY_TYPES.ALIPAY.type" class="itemradio">
              <div class="itemradio-content">
                <img class="icon-alipay" src="@/assets/icon-alipay.png"/>支付宝支付
              </div>
            </el-radio>
          </el-radio-group>

          <el-button
              :disabled="subAccount || !priceFetched"
              class="paybtn"
              size="small"
              type="primary"
              @click="toOrderConfirmPage">提交订单
          </el-button>
        </div>
      </div>
      <div class="content-left">
        <div class="diyu">
          <p class="title-wrap">
            <i class="iconfont icon-aim-region titleicon"></i><span class="title">路由器(随机发货)</span>
          </p>
          <template v-for="router in routerTypes">
            <el-card :key="router.id" class="imgCard" shadow="hover">
              <el-image :alt="router.type + '图片'" :src="router.image" fit="fit" class="routerImg"
                        :preview-src-list="[router.image]"></el-image>
              <div class="routerDesc">
                <span class="desc" v-html="router.desc.replace(/\n/g, '<br>')"></span>
<!--                <div class="imgFoot">-->
<!--                  <el-radio v-model="chooseRouter" :label="router.type">-->
<!--                    <span>{{ router.type }}</span>-->
<!--                  </el-radio>-->
<!--                </div>-->
              </div>
            </el-card>
          </template>
        </div>

        <div class="gmsl">
          <p class="title-wrap">
            <i class="iconfont icon-buycount titleicon"></i><span class="title">购买数量</span>
          </p>
          <div class="select-number">
            <el-input-number v-model="buyCount" :max="200" :min="1" size="small"></el-input-number>
          </div>
        </div>

        <div class="gmsl">
          <p class="title-wrap">
            <i class="iconfont icon-buycount titleicon"></i><span class="title">路由器名称</span>
          </p>
          <div class="select-number">
            <el-input v-model="routerName" style="width: 180px" size="small"
                      placeholder="请填写路由器名称"
            ></el-input>
          </div>
        </div>

        <div class="gmsl">
          <p class="title-wrap">
            <i class="iconfont icon-buycount titleicon"></i><span class="title">收件信息</span>
          </p>
          <el-form ref="receiverForm" :model="receiver" label-width="100px" :rules="receiverRules">
            <el-form-item label="收件人" prop="name">
              <el-input style="width: 180px"
                        size="small"
                        v-model="receiver.name"
                        placeholder="请填写收件人">
              </el-input>
            </el-form-item>
            <el-form-item label="收件号码" prop="phone">
              <el-input style="width: 180px"
                        size="small"
                        v-model="receiver.phone"
                        placeholder="请填写收件号码">
              </el-input>
            </el-form-item>
            <el-form-item label="收件地址" prop="address">
              <el-input style="width: 300px"
                        size="small"
                        type="textarea"
                        :rows="3"
                        v-model="receiver.address"
                        placeholder="请填写收件地址">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {centToYuan, ROUTER_RENT_DECLARATION, ROUTER_TYPES} from '../Constants.js'
import navigation from "@/utils/navigation.js";
import {PAY_TYPE_MAP} from "@/utils/constants.js";
import {mapGetters} from "vuex";

export default {
  name: "ApplyRouter",
  computed: {
    ...mapGetters(["subAccount"]),
  },
  data() {
    return {
      PAY_TYPES: PAY_TYPE_MAP,
      routerTypes: [],
      chooseRouter: '',
      buyCount: 1,
      receiver: {
        name: '',
        phone: '',
        address: ''
      },
      receiverRules: {
        name: [
          {required: true, message: '收件人不能为空'}
        ],
        phone: [
          {required: true, message: '收件号码不能为空'},
          {trigger: 'blur',
            validator: (rule, value, callback) => {
              const valid = value.length === 11 && /^1[0-9|-]*$/.test(value)
              if (valid) {
                callback()
              } else {
                callback("不正确的号码")
              }
            }
          }
        ],
        address: [
          {required: true, message: '收件地址不能为空'}
        ]
      },
      routerName: '',
      totalAmount: 0,
      discounts: 0,
      payType: '',
      balanceEnough: true,
      balance: 0,
      priceFetched: false
    }
  },
  methods: {
    routerPrice(oldRouterModel) {
      this.$store.dispatch('routers/routerPrice',
          {productId: this.chooseRouter, buyCount: this.buyCount}
      ).then(ret => {
        this.totalAmount = centToYuan(ret.TotalAmount)
        this.discounts = centToYuan(ret.Discounts)
        this.priceFetched = true
        this.payTypeControl(this.balance >= ret.TotalAmount)
      })
      .catch(err => {
        this.$message.error(`获取路由器价格失败： ${err}`)
        this.priceFetched = false
        // 获取失败，选回旧的
        if (oldRouterModel) {
          setTimeout(() => {
            this.chooseRouter = oldRouterModel
          }, 1000)
        }
      })
    },

    async toOrderConfirmPage() {
      // alert("由于线路火爆，路由已售空，正在疯狂补货中，预计七个工作日之后到货。给您带来的不便之处，敬请见谅！！！感谢您的理解")
      const valid = await this.$refs.receiverForm.validate().catch(() => {})
      if (!valid) {
        this.$message.warning("收件信息缺少必填项！")
        return;
      }
      navigation.navigateTo({
        url: "router_order_confirm",
        params: {
          payload: {
            routerType: this.chooseRouter,
            buyCount: this.buyCount,
            total: this.totalAmount,
            PayType: this.payType,
            name: this.routerName || this.chooseRouter,
            receiver: this.receiver
          }
        },
      });
    },

    payTypeControl(useBalance = true) {
      this.balanceEnough = useBalance;
      this.payType = (useBalance ? this.PAY_TYPES.BALANCE : this.PAY_TYPES.WECHAT_NATIVE).type;
    },

    getUserBalance() {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        if (res.RetCode === 0) {
          this.balance = res.Balance;

          this.payTypeControl(this.balance > 0)
        }
      });
    },
  },
  created() {
    // todo: 当前支持的所有路由器类型在前端配置，若后续需要用数据库管理，则可改成从接口查询
    this.routerTypes = Object.entries(ROUTER_TYPES)
        .map(([type, {image, desc}], id) => ({ type, image, desc, id }))
    this.chooseRouter = 'ZBT3000'
    this.getUserBalance()
  },

  activated() {
    this.getUserBalance()
  },

  mounted() {
    // 申请路由器时，对押金、扣费、退押金规则明确提示
    // 弹个框告知用户规则
    this.$alert(ROUTER_RENT_DECLARATION, '路由器申请须知', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '我已知晓',
      showClose: false
    })
  },

  watch: {
    chooseRouter(router, old) {
      this.routerPrice(old);
    },
    buyCount(count, old) {
      this.routerPrice()
    },
    balance(newBalance) {
      this.payTypeControl(newBalance >= (this.totalAmount || 0) * 100)
    }
  },

  filters: {
    centToYuan(cent) {
      return centToYuan(cent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
